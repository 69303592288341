import {useState} from "react";
// Components
import {Page} from "../layouts/components";
// @MUI
import {Box, Button, CircularProgress, Container, Paper} from "@mui/material";
import {
    Microsoft as MicrosoftIcon
} from "@mui/icons-material"
// Custom
import {useQuery} from "../hooks/useQuery";
// Redux
import {useAppDispatch, useAppSelector} from "../store/store";
import {checkUserAuth} from "../store/user/userFunction";

const loginChannel = new BroadcastChannel('AUTHENTICATION')

// Login page component
export default function Login() {
    const dispatch = useAppDispatch();

    const [open, setOpen] = useState<boolean>(false)
    const loading = useAppSelector(state => state.user.loading)

    // OPEN LOGIN WINDOW
    const openLogin = () => {
        setOpen(true);

        const loginWindow = window.open(`${process.env.REACT_APP_AWS_MCP_API}/user/auth${(process.env.REACT_APP_ENV !== 'PRODUCTION') ? ('?environment=' + process.env.REACT_APP_ENV) : ''}`, '_blank', 'location=0,width=450,height=600') as Window;

        // Stop load on original window if login window is closed
        setInterval(() => {
            if (loginWindow.closed) {
                setOpen(false)
            }
        }, 500);
    }

    // retrieve Query Parameters
    const query = useQuery();

    // check if confirmed query is true
    if (query.get('confirmed')) {
        loginChannel.postMessage('AUTHENTICATED');
        window.close();
    }

    // Receive message from get user
    loginChannel.onmessage = (event) => {
        if (event.data === 'AUTHENTICATED') {
            dispatch(checkUserAuth())
            loginChannel.close()
        } else if (event.data === 'CLOSED') {
            setOpen(false);
        }
    }

    return (
        <Page title='MiPad - Login'>
            <Container
                maxWidth='sm'
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <Paper
                    sx={{
                        bgcolor: 'grey.100',
                        borderRadius: 2,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // minHeight: '80vh',
                        m: 2,
                        p: 2,
                        pb: 20,
                        height: '700px'
                    }}
                    elevation={10}
                >
                    <Box sx={{
                        textAlign: 'center',
                        p: 2,
                        width: '100%',
                        heigth: '100%',
                    }}>
                        <Box
                            sx={{
                                p: 2,
                                pb: 10,
                                width: '50%'
                            }}
                            component='img'
                            src='/static/stacked_metrics.png'
                            alt='Metrics'
                        />
                        {(loading || open) ?
                            <Box
                                sx={{display: 'flex', flexDirection: 'center', justifyContent: 'center'}}
                            >
                                <CircularProgress/>
                            </Box>
                            :
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={openLogin}
                                sx={{
                                    p: 2,
                                    alignItems: 'center',
                                    alighnContent: 'center',
                                    display: 'flex'
                                }}
                            >
                                <Box sx={{
                                    pr: 1,
                                    align: 'center',
                                    display: 'flex'

                                }}>
                                    <MicrosoftIcon/>
                                </Box>
                                <Box sx={{
                                    pr: 1,
                                    align: 'center',
                                    display: 'flex'
                                }}
                                >
                                    Continue with Microsoft
                                </Box>
                            </Button>
                        }
                    </Box>
                </Paper>

            </Container>

        </Page>
    )
}