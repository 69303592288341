import {useEffect} from "react";
import {useSearchParams} from "react-router-dom";
// REDUX
import {useAppDispatch} from "../store/store";
import {checkUserAuth} from "../store/user/userFunction";

// Checks user if authenticated, if authenticated passes to last location or dashboard.
export function AuthenticationCheck({authenticated, children}: {authenticated: boolean, children: JSX.Element}) {
    let [searchParams] = useSearchParams();

    const dispatch = useAppDispatch();

    // Used to reroute to page before authentication
    const redirect = searchParams.get('redirect');

    // Check if Active user session
    useEffect(() => {
        if (!authenticated) {
            dispatch(checkUserAuth())
        }
    }, [authenticated, dispatch])

    // If user in state reroute to page
    if (authenticated) {
        if (redirect) {
            window.location.href = redirect;
        } else {
            window.location.href = `${process.env.REACT_APP_HOME_APP}/`;
        }
    }

    // Default Navigate to unauthenticated
    return children;
}