import {useEffect} from "react";
import {Navigate} from "react-router-dom";
import {useAppDispatch} from "../store/store";
import {signOut} from "../store/user/userFunction";
import {Box, CircularProgress, Container, Paper} from "@mui/material";
import {Page} from "../layouts/components";

// User Logout Function
export default function Logout({authenticated}: { authenticated: boolean }) {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(signOut())
    }, [dispatch])

    if (authenticated) {
        return <Page title='MiPad - Login'>
            <Container
                maxWidth='sm'
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <Paper
                    sx={{
                        bgcolor: 'grey.100',
                        borderRadius: 2,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // minHeight: '80vh',
                        m: 2,
                        p: 2,
                        pb: 20,
                        height: '700px'
                    }}
                    elevation={10}
                >
                    <Box sx={{
                        textAlign: 'center',
                        p: 2,
                        width: '100%',
                        heigth: '100%',
                    }}>
                        <Box
                            sx={{
                                p: 2,
                                pb: 10,
                                width: '50%'
                            }}
                            component='img'
                            src='/static/stacked_metrics.png'
                            alt='Metrics'
                        />
                        <CircularProgress/>
                    </Box>
                </Paper>

            </Container>

        </Page>
    } else {
        return <Navigate to="/login"/>;
    }
}