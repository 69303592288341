import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosError} from "axios";

import {apiGetRequests, apiPostRequests} from "../apiUtils";
import {addNotification} from "../notifications/notificationSlice";
import {RootState} from "../store";

// QUERY API IF VALID COOKIE AND USER
export const checkUserAuth = createAsyncThunk('user/checkUserAuth', async (_, thunkAPI) => {
    try {
        // CHECKED IF VALID TOKENS IN COOKIE
        await apiGetRequests('/');
        thunkAPI.dispatch(getUser());
    } catch (e) {
        const error = e as AxiosError;
        if (error.name === 'AxiosError') {
            // API will provide a response when rejecting, if response contains refresh: true, this means the user can attempt to refresh their tokens.
            const errorRes = error?.response?.data as {refresh: string | undefined};
            if (errorRes && errorRes?.refresh && errorRes.refresh === 'true') {
                try {
                    await apiPostRequests('/user/auth/refresh');

                    await apiGetRequests('/');
                    thunkAPI.dispatch(getUser());
                } catch (e) {
                    return thunkAPI.rejectWithValue(error);
                }
            } else {
                return thunkAPI.rejectWithValue(error);
            }
        } else {
            console.log(error)
            return thunkAPI.rejectWithValue(error);
        }
    }
})

// RETRIEVE USER
export const getUser = createAsyncThunk('user/getUser', async (_, thunkAPI) => {
    try {
        return apiGetRequests('/user');
    } catch (error) {
        let message;
        if (error instanceof Error) {
            message = `Error: ${error.message}`;
        } else {
            message = 'Problem occurred retrieving User';
        }
        thunkAPI.dispatch(addNotification(message, 'error'))
        return thunkAPI.rejectWithValue(message);
    }
})

// SignOUt User
export const signOut = createAsyncThunk('user/signOut', async (_, thunkAPI) => {
    try {
        // Clear Cookies
        await apiPostRequests('/user/signOut');

        // UI Notification
        const state = thunkAPI.getState() as RootState;

        let confirm = 'Successfully Logged Out!';
        if (state.user?.user?.givenName) {
            confirm = `Goodbye ${state.user.user.givenName}!`
        }

        thunkAPI.dispatch(addNotification(confirm, 'success'));

        return;
    } catch (error) {
        thunkAPI.dispatch(addNotification('Error Signing out.', 'error'));
        console.log('Error signing out: ', error);
    }
})