import {Navigate, Route, Routes} from 'react-router-dom'
// Components
import {MinimalLayout} from "../layouts";
import {AuthenticationCheck} from "./routerUtils";
// Pages
import {Login, Logout, NotFound} from "../Pages";
// Store
import {useAppSelector} from "../store/store";
import {isAuthenticated} from "../store/user/userSelector";
import useNotifier from "../hooks/useNotifier";


/**
 * Handles the Routing for the application
 *
 * Protect routes  from unauthorised access
 * If user has logged in reauthenticates users and redirects to page
 *
 */
export default function Router() {
    const authenticated = useAppSelector(state => isAuthenticated(state));

    // Introducers Notifiers
    useNotifier();

    return (
        <Routes>
            <Route path='/logout' element={<Logout authenticated={authenticated} />} />

            {/*UNAUTHENTICATED ROUTES*/}
            <Route path='/' element={<AuthenticationCheck authenticated={authenticated}><MinimalLayout /></AuthenticationCheck>}>
                <Route path='/login' element={<Login />} />
                <Route path='/' element={<Navigate to="/login" />} />
                {/* PATH NOT FOUND */}
                <Route path='/404' element={<NotFound />} />
                <Route path='*' element={<NotFound />} />
            </Route>

            {/*/!* Routes unauthorised/authorised sections with a layout and wrapper to determine authorisation process *!/*/}
            {/*/!* UNAUTHENTICATED ROUTES *!/*/}
            {/*<Route path='/' element={<Login/>}></Route>*/}

            {/*/!* PATH NOT FOUND *!/*/}
            {/*<Route path='*' element={<Navigate to="/"/>}/>*/}
        </Routes>
    )
}