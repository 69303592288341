import axios from 'axios'

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_AWS_MCP_API,
    headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_AWS_MCP_API_KEY
    },
    withCredentials: true
});


// GENERIC HANDLER FOR ALL API GET REQUESTS
export async function apiGetRequests(query: string): Promise<any> {
    const response = await axiosInstance.get(query);
    return response.data;
}

// GENERIC HANDLER FOR ALL API GET REQUESTS
export async function apiPostRequests(query: string, body?: any): Promise<any> {
    const response = await axiosInstance.post(query, body);
    return response.data;
}